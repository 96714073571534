import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import PageHero from "../components/common/page-hero"

const PrivacyPolicyPage = () => (
  <Layout>
    <Seo />
    <PageHero title="Privacy Policy" />

    <div className="bg-gray-100">
      <div className="w-11/12 max-w-screen-lg mx-auto pt-16 pb-20">
        <h2 className="text-2xl mb-8">The HireLive.com Website</h2>
        <p className="text-lg mb-6">This HireLive.com website (the “Website”) is owned and operated by HireLive Inc.</p>
        <h2 className="text-2xl mt-10 mb-8">Privacy of Your Information</h2>
        <p className="text-lg mb-6">This Privacy Policy describes how HireLive treats the information that we collect from visitors (“you”) to the Website. Your privacy and the security of your information are very important to us. We are dedicated to protecting the privacy of those who visit our Website. This Privacy Policy governs the Website and explains how your information is collected on our Website, how your information is protected, and the choices you have concerning the use of such information. Please read this Privacy Policy carefully.This Privacy Policy applies only to HireLive’s receipt and use of your information. By submitting your resume, you opt-in and agree to your information being used and may be shared with potential employers about potential job opportunities. Your resume or information you submit maybe used and shared, you may be called, emailed, texted, interviewed or set up for interviews. Submitting your information is never a guarantee of an interview or being hired.</p>
        <p className="text-lg mb-6">We may amend this Privacy Policy from time to time. We will post any changes to this Privacy Policy here so that you will always know what information we gather, how that information may be used, and whether that information will be disclosed to anyone. Please refer back to this Privacy Policy on a regular basis. By using our Website or any of our products, offerings, features, tools or resources that we provide on our Website, you agree to the terms of this Privacy Policy.</p>
        <p className="text-lg mb-6">PLEASE NOTE: ALTHOUGH WE WORK HARD TO PROTECT THE PRIVACY OF YOUR PERSONAL INFORMATION, WE CANNOT GUARANTEE THAT YOUR PERSONAL INFORMATION WILL REMAIN PRIVATE. PROVIDING PERSONAL INFORMATION TO HIRELIVE THROUGH THIS WEBSITE IS VOLUNTARY AND, IF YOU DO SO, YOU DO SO AT YOUR OWN RISK. YOU CAN ALWAYS CHOOSE NOT TO PROVIDE US WITH YOUR PERSONAL INFORMATION, EVEN THOUGH IT MIGHT BE NEEDED TO TAKE ADVANTAGE OF A SERVICE.</p>
        <p className="text-lg mb-6 font-bold">1. What Information About Me Is Collect On Our Website And How Is It Collected?</p>
        <p className="text-lg mb-6">We collect two types of information: (a) personally identifiable information and (b) non-personally identifiable information.</p>
        <p className="text-lg mb-6">a. Personally Identifiable Information</p>
        <p className="text-lg mb-6">Personal information is information about you, the user, that is personally identifiable like your name, address, email address, or phone number, and that is not otherwise publicly available. Any information that is on your resume that is submitted onto the website is collected. This information can be used for phone calls and text or email campaigns.  If you do not want anyone to have access to certain pieces of your information, please keep it off your resume.</p>
        <p className="text-lg mb-6">b. Non-Personally Identifiable Information: Cookies and Web Beacons</p>
        <p className="text-lg mb-6">Personal information is information about you, the user, that is personally identifiable like your name, address, email address, or phone number, and that is not otherwise publicly available. Any information that is on your resume that is submitted onto the website is collected. If you do not want anyone to have access to certain pieces, please leave them off of your resume.</p>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicyPage